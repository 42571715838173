
import { Vue, Component, Watch } from 'vue-property-decorator'
import { BModal } from 'bootstrap-vue'
import Profile from '@/components/Profile.vue'

@Component({
  components: { Profile, },
  props: ['profile', 'preventEdit'],
})
export default class ProfileContainer extends Vue {
  $refs!: {
    confirmReview: BModal
  }

  profile!: any
  preventEdit!: boolean

  reviewStatus: any = null

  confirmProfileReview (profile: any, status: string) {
    this.reviewStatus = status

    if (profile.status == 'wait_review') {
      // 적용 전 status 가 wait_review == 승인된 회원
      this.$refs.confirmReview.show()
    } else {
      this.reviewProfile()
    }
  }

  reviewProfile () {
    this.$store.dispatch('reviewProfile', {
        id: this.profile.id,
        params: {
          status: this.reviewStatus,
          rejection_comment: this.profile.rejection_comment,
          review_token_confirmation: this.profile.review_token,
        }
      })
      .finally(() => {
        this.$emit('reload')
        this.$refs.confirmReview.hide()
      })
  }

  reloadUser () {
    this.$emit('reload')
  }
}
