
import { Vue, Component, Watch } from 'vue-property-decorator'
import CategoryBreadcrumb from '@/components/CategoryBreadcrumb.vue'
import UserBadges from '@/components/UserBadges.vue'
import Profile from '@/components/Profile.vue'
import ChurchForm from '@/components/ChurchForm.vue'

@Component({
  components: { CategoryBreadcrumb, UserBadges, Profile, ChurchForm }
})
export default class Churches extends Vue {
  users: any = []
  counts: any = {
    churches: 0,
  }

  mounted () {
    this.onCategoryChanged()
  }

  get categories () {
    return [
      { key: 'churches', text: '교회정보', route: { name: 'review-churches', params: { category: 'churches' } } },
    ]
  }

  get category () { return (this.$route.params.category || this.categories[0].key).replace(/-/g, '_') }

  @Watch('category')
  onCategoryChanged () {
    this.users = []

    if (this.category) {
      this.$store.dispatch('getWaitReviewChurches')
        .then(({data}) => {
          this.users = data.users
          this.counts = data.counts
        })
    }
  }

  /**
   * @method getWaitReview
   * @param {User} user 유저 데이터
   * @returns {Profile} 
   * @description 전달받은 유저 데이터에서 심사 대기중인 항목 하나 가져오기
   */
  getWaitReview (user: any) {
    const churches = [
      user.profile.church_name,
      user.profile.church_location,
      user.profile.pastor_name
    ]

    return churches.find((profile) => { return profile.status == 'wait_review' })
  }

  /**
   * @method hasWaitReview
   * @param {User} user 유저 정보
   * @returns {boolean}
   * @description 전달받은 사진 목록에서 심사 대기중인 사진이 있는지 여부 확인
   */
  hasWaitReview (user: any) {
    return this.getWaitReview(user) != undefined
  }

  /**
   * @method approveWaitReviews
   * @param {User} user 유저 정보
   * @description 심사 대기중인 프로필들을 재귀로 돌면서 승인 대기 처리
   */
  approveWaitReviews (user: any) {
    const profile = this.getWaitReview(user)

    if (profile) {
      this.$set(profile, 'status', 'wait_approve')
      this.approveWaitReviews(user)
    }
  }

  reviewProfile (profile: any, status: string) {
    this.$set(profile, 'status', status)
  }

  async confirmReviews (user: any) {
    if (await this.$bvModal.msgBoxConfirm('심사를 반영하시겠습니까? (* 반영 이후엔 되돌릴 수 없습니다.)', { okVariant: 'dark', autoFocusButton: 'ok' })) {
      const requests: Promise<void>[] = []

      const churches = [
        user.profile.church_name,
        user.profile.church_location,
        user.profile.pastor_name
      ]

      churches.forEach(async (profile) => {
        if (['wait_reject', 'wait_approve'].includes(profile.status)) {
          requests.push(
            this.$store.dispatch('reviewProfile', {
              id: profile.id,
              params: {
                status: profile.status,
                rejection_comment: profile.rejection_comment,
                review_token_confirmation: profile.review_token
              }
            })
          )
        }
      })

      Promise.all(requests).finally(() => {
        this.$bvToast.toast(`[User#${user.id}] 심사가 반영되었습니다.`, {
          title: '알림',
          variant: 'info'
        })
        this.onCategoryChanged()
      })
    }
  }

  getChurch (user: any) {
    this.$store.dispatch('getUserChurch', { userId: user.id })
      .then(({ data }) => this.$set(user, 'church', data))
  }
}
