
import { Vue, Component, Watch } from 'vue-property-decorator'
import InfiniteLoading from 'vue-infinite-loading'
import CategoryBreadcrumb from '@/components/CategoryBreadcrumb.vue'
import UserCard from '@/components/UserCard.vue'
import { mapState } from 'vuex'

@Component({
  components: { UserCard, CategoryBreadcrumb },
  computed: { ...mapState(['isLoading']) },
})
export default class Users extends Vue {
  $refs!: {
    infiniteLoading: InfiniteLoading
  }

  userIds: any = []
  users: any = []
  counts: any = { on_review: 0, wait_reject: 0, wait_approve: 0 }

  viewFilter: string = this.$store.state.usersReviewFilter

  get viewFilterOptions () {
    return [
      { text: '전체', value: 'all' },
      { text: '남성', value: 'male' },
      { text: '여성', value: 'female' }
    ]
  }

  get categories () {
    return [
      { key: 'on_review', text: '심사 중', route: { name: 'review-users', params: { category: 'on-review' } } },
      { key: 'wait_reject', text: '반려 대기', route: { name: 'review-users', params: { category: 'wait-reject' } } },
      { key: 'wait_approve', text: '승인 대기', route: { name: 'review-users', params: { category: 'wait-approve' } } },
      { key: 'approved', text: '승인된 회원', route: { name: 'review-users', params: { category: 'approved' } } },
    ]
  }

  get category () { return (this.$route.params.category || this.categories[0].key).replace(/-/g, '_') }

  viewFilterClass (user: any) {
    if (this.viewFilter != 'all' && this.viewFilter != user.gender) {
      return 'd-none'
    } else {
      return ''
    }
  }

  @Watch('viewFilter')
  setUsersReviewFilter (value: string) {
    this.$store.commit('setUsersReviewFilter', value)
  }

  @Watch('category')
  resetUsers () {
    this.userIds = []
    this.users = []
    this.$refs.infiniteLoading.$emit('$InfiniteLoading:reset')
  }

  getUsers ($state: any) {
    this.$store.dispatch('getUsers', { params: {status: this.category, offset: this.users.length }})
      .then(({data}) => {
        this.counts = data.counts

        if (data.users.length > 0) {
          // 리스트에 있는 데이터 ID값 추출
          this.userIds = this.users.map((user: any) => user.id)

          // 이미 리스트에 있는 데이터는 중복으로 노출되지 않도록 필터
          this.users.push(...data.users.filter((user: any) => this.userIds.indexOf(user.id) === -1))

          $state.loaded()
        } else {
          $state.complete()
        }
      })
  }
}
